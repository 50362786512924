import { FC, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../hooks/useAuth';

type SideJobFormData = {
  title: string;
  companyName: string;
  specialties: string[];
  salary: number;
  workContent: string;
  status: string;
};

type SendEmailData = {
  email: string;
  subject: string;
  text: string;
};

export const SideJobRegisterConfirm: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const formData = location.state as SideJobFormData;
  const [isLoading, setIsLoading] = useState(false);

  const generateEmailContent = (formData: SideJobFormData) => {
    const emailSubject = '副業求人登録完了のお知らせ';
    const emailText = `
      以下の内容で求人が登録されました:

      求人タイトル: ${formData.title}
      企業名: ${formData.companyName}
      診療科: ${formData.specialties.join(', ')}
      給与: ${formData.salary}
      勤務内容・条件: ${formData.workContent}
      
      登録が完了しました。ご確認ください。
    `;

    return { emailSubject, emailText };
  };

  const handleConfirm = async () => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    setIsLoading(true); // ローディング状態を開始

    try {
      // Adding document and capturing the generated ID
      const docRef = await addDoc(collection(db, 'SideJobListings'), {
        ...formData,
        hospitalUID: user.uid, // 医療機関のUIDを追加
        status: '未確', // 新規登録時にデフォルトのステータスを設定
      });

      // Updating the document to include the ID
      await updateDoc(doc(db, 'SideJobListings', docRef.id), {
        id: docRef.id,
      });

      // メール送信内容を生成
      const { emailSubject, emailText } = generateEmailContent(formData);

      // メール送信
      const sendEmailData: SendEmailData = {
        email: user.email!,
        subject: emailSubject,
        text: emailText,
      };

      const firebaseFunctionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

      const response = await fetch(`${firebaseFunctionsUrl}/sendWelcomeEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendEmailData),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(`Error sending email: ${responseData.error}`);
      }

      // 管理者にメール送信
      const adminSendEmailData: SendEmailData = {
        email: 'medpark727@gmail.com',
        subject: '新しい副業求人登録のお知らせ',
        text: emailText,
      };

      const adminResponse = await fetch(
        `${firebaseFunctionsUrl}/sendWelcomeEmail`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(adminSendEmailData),
        }
      );

      if (!adminResponse.ok) {
        const responseData = await adminResponse.json();
        throw new Error(`Error sending email to admin: ${responseData.error}`);
      }

      navigate('/side-job-register-complete', { state: formData });
    } catch (error: any) {
      console.error('Error adding document: ', error.message);
      alert(`エラーが発生しました: ${error.message}`);
    } finally {
      setIsLoading(false); // ローディング状態を終了
    }
  };

  const handleBack = () => {
    navigate('/side-job-register', { state: formData });
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          求人情報確認
        </Heading>
        <Divider my={4} />
        <Stack spacing={4}>
          <Text>求人タイトル: {formData.title}</Text>
          <Text>掲載元企業名: {formData.companyName}</Text>
          <Text>診療科: {formData.specialties.join(', ')}</Text>
          <Text>給与: {formData.salary}</Text>
          <Text>業務内容・条件等: {formData.workContent}</Text>
          <Button
            colorScheme="teal"
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            登録する
          </Button>
          <Button
            colorScheme="gray"
            onClick={handleBack}
            isDisabled={isLoading}
          >
            戻る
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
