import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiSelect, Option } from 'react-multi-select-component';
import { prefectures, specialties, workTypes } from '../../constants/constants';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import '../../styles.css';
import { db } from '../../firebase';
import { PartJobListings } from '../../types/PartJobListings';
import {
  AtSignIcon,
  EmailIcon,
  LockIcon,
  SearchIcon,
  TimeIcon,
} from '@chakra-ui/icons';
import { useAuth } from '../../hooks/useAuth';

type PartFormData = {
  prefectures: string[];
  workDays: string[];
  workTimes: string[];
  specialties: string[];
  salaryRange: Option[];
};

const salaryOptions: Option[] = [
  { label: '80,000円 - 90,000円', value: '80000-90000' },
  { label: '90,000円 - 100,000円', value: '90000-100000' },
  { label: '100,000円 - 110,000円', value: '100000-110000' },
  { label: '110,000円 - 120,000円', value: '110000-120000' },
  { label: '120,000円以上', value: '120000-' },
];

const workDaysOptions: Option[] = [
  { label: '月', value: 'Monday' },
  { label: '火', value: 'Tuesday' },
  { label: '水', value: 'Wednesday' },
  { label: '木', value: 'Thursday' },
  { label: '金', value: 'Friday' },
  { label: '土', value: 'Saturday' },
  { label: '日', value: 'Sunday' },
];

const PartSearch: FC = () => {
  const { user, login, loading } = useAuth();
  const { register, handleSubmit } = useForm<PartFormData>();
  const [selectedWorkDays, setSelectedWorkDays] = useState<Option[]>([]);
  const [selectedPrefectures, setSelectedPrefectures] = useState<Option[]>([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState<Option[]>([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState<Option[]>([]);
  const [selectedSalaryRange, setSelectedSalaryRange] = useState<Option[]>([]);
  const [allJobListings, setAllJobListings] = useState<PartJobListings[]>([]);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string | null>(null);

  const onSubmit = (data: PartFormData) => {
    const workDayValues = selectedWorkDays.map((day) => day.value);
    const prefectureValues = selectedPrefectures.map((p) => p.value);
    const workTypeValues = selectedWorkTypes.map((w) => w.value);
    const specialtyValues = selectedSpecialties.map((s) => s.value);
    const salaryRanges = selectedSalaryRange.map((s) => s.value);

    navigate('/part-results', {
      state: {
        ...data,
        workDays: workDayValues,
        prefectures: prefectureValues,
        workTypes: workTypeValues,
        specialties: specialtyValues,
        salaryRange: salaryRanges,
      },
    });
  };

  const handleLogin = async (email: string, password: string) => {
    try {
      await login(email, password);
    } catch (error) {
      setLoginError('ログインに失敗しました。もう一度お試しください。');
    }
  };

  useEffect(() => {
    const fetchAllJobListings = async () => {
      try {
        const q = query(collection(db, 'PartJobListings'), orderBy('id'));
        const querySnapshot = await getDocs(q);
        const jobListingsData = querySnapshot.docs.map((doc) => {
          const data = doc.data() as Omit<PartJobListings, 'id'>;
          return { id: doc.id, ...data };
        });
        setAllJobListings(jobListingsData);
      } catch (error) {
        console.error('求人情報の取得に失敗しました:', error);
      }
    };

    fetchAllJobListings();
  }, []);

  if (!user) {
    return (
      <Flex align="center" justify="center" height="100vh" my={0}>
        <Box
          bg="white"
          w={{ base: '100%', md: 'sm' }}
          p={0}
          borderRadius="md"
          shadow="md"
        >
          <Box position="relative" textAlign="center">
            <Image
              src="/assets/images/register.png"
              alt="ログイン画像"
              mb={4}
            />
            <Text
              position="absolute"
              top="70%"
              left="50%"
              width="80%"
              transform="translate(-50%, -50%)"
              color="white"
              fontWeight="bold"
              fontSize="md"
            >
              会員の方はこちらからログインしてください。会員登録がまだの方は会員登録してください。
            </Text>
          </Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              const email = formData.get('email') as string;
              const password = formData.get('password') as string;
              handleLogin(email, password);
            }}
          >
            <Stack spacing={6} py={4} px={10}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="black" />
                </InputLeftElement>
                <Input
                  placeholder="メールアドレス"
                  _placeholder={{ color: 'black' }}
                  borderColor="black"
                  borderRadius="20"
                  pl="10"
                  name="email"
                  required
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="black" />
                </InputLeftElement>
                <Input
                  placeholder="パスワード"
                  _placeholder={{ color: 'black' }}
                  borderColor="black"
                  borderRadius="20"
                  pl="10"
                  type="password"
                  name="password"
                  required
                />
              </InputGroup>
              {loginError && <Text color="red.500">{loginError}</Text>}
              <Button
                type="submit"
                isLoading={loading}
                borderRadius="20"
                backgroundColor="#949495"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="40px"
                width="100%"
                mx="auto"
                display="block"
              >
                <Flex justify="space-between" align="center" width="100%">
                  <Box>ログイン</Box>
                  <AtSignIcon />
                </Flex>
              </Button>
            </Stack>
          </form>
          {/* <Button
            as="a"
            href="https://m-e-racle.com/contact/"
            target="_blank"
            rel="noopener noreferrer"
            borderRadius="20"
            marginTop="20px"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            w="80%"
            display="flex"
            justifyContent="center"
            mx="auto"
            style={{
              background: 'linear-gradient(to right, #03172D, #213C80)',
              borderRadius: 20,
            }}
            color="white"
          >
            お問い合わせ
          </Button> */}
        </Box>
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" minHeight="100vh" mt={50}>
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        pb={10}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/work.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            非常勤求人検索
          </Text>
        </Box>
        <Box w="90%" mx="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <Text fontWeight="bold" color="black">
                勤務曜日
              </Text>
              <MultiSelect
                options={workDaysOptions}
                value={selectedWorkDays}
                onChange={setSelectedWorkDays}
                labelledBy="勤務曜日を選択"
              />
              <Text fontWeight="bold" color="black">
                都道府県
              </Text>
              <MultiSelect
                options={prefectures.map((pref) => ({
                  label: pref,
                  value: pref,
                }))}
                value={selectedPrefectures}
                onChange={setSelectedPrefectures}
                labelledBy="都道府県を選択"
              />
              <Text fontWeight="bold" color="black">
                勤務体系
              </Text>
              <MultiSelect
                options={workTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
                value={selectedWorkTypes}
                onChange={setSelectedWorkTypes}
                labelledBy="勤務体系を選択"
              />
              <Text fontWeight="bold" color="black">
                診療科
              </Text>
              <MultiSelect
                options={specialties.map((specialty) => ({
                  label: specialty,
                  value: specialty,
                }))}
                value={selectedSpecialties}
                onChange={setSelectedSpecialties}
                labelledBy="診療科を選択"
              />
              <Text fontWeight="bold" color="black">
                給料範囲
              </Text>
              <MultiSelect
                options={salaryOptions}
                value={selectedSalaryRange}
                onChange={setSelectedSalaryRange}
                labelledBy="給料範囲を選択"
              />
              <Button
                type="submit"
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="20px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="40px"
              >
                検索する
              </Button>
            </Stack>
          </form>
        </Box>

        <Divider my={4} />
        <Box className="css-a6f6du" p={4}>
          <Heading size="md" mb={4}>
            全ての非常勤求人一覧
          </Heading>
          {allJobListings.map((job) => (
            <Box
              key={job.id}
              p={4}
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
              w={{ base: '90%', lg: '50%' }}
              mx="auto"
              mb={4}
            >
              <Box
                display="inline-block"
                borderRadius="full"
                px={3}
                py={1}
                bg="gray.100"
              >
                <Text fontSize={15} color="#40BA8D">
                  ID: {job.id}
                </Text>
              </Box>

              <HStack>
                <Text fontWeight="bold" color="black">
                  一回 {job.salary}円
                </Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold" color="black">
                  {job.title}
                </Text>
              </HStack>
              <HStack>
                <AtSignIcon />
                <Text color="black">{job.address}</Text>
              </HStack>
              <HStack>
                <TimeIcon />
                <Text color="black">
                  {Array.isArray(job.workDays)
                    ? job.workDays.join(', ')
                    : job.workDays}
                </Text>
                <Text color="black">{job.workTime}</Text>
              </HStack>

              {job.status !== '確定' && (
                <Button
                  borderRadius="20"
                  backgroundColor="#949495"
                  marginTop="10px"
                  color="white"
                  _hover={{ backgroundColor: '#737373' }}
                  height="30px"
                  onClick={() => navigate(`/part-detail/${job.id}`)}
                >
                  詳細を見る
                </Button>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Flex>
  );
};

export default PartSearch;
