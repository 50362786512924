import { FC, useEffect, useState } from 'react';
import { FaYenSign } from 'react-icons/fa';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { AtSignIcon, TimeIcon } from '@chakra-ui/icons';

type SideJobListing = {
  id: string;
  title: string;
  companyName: string;
  specialties: string[];
  salary: number; // salaryをnumber型に変更
  workContent: string;
  status: string;
};

export const SideJobResults: FC = () => {
  const navigate = useNavigate();
  const [jobListings, setJobListings] = useState<SideJobListing[]>([]);

  useEffect(() => {
    const fetchJobListings = async () => {
      try {
        const collectionRef = collection(db, 'SideJobListings');
        const querySnapshot = await getDocs(collectionRef);

        const allResults = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<SideJobListing, 'id'>),
        }));

        setJobListings(allResults);
      } catch (error) {
        console.error('求人情報の取得に失敗しました:', error);
      }
    };

    fetchJobListings();
  }, []);

  const formatCondition = (condition: string[]) =>
    condition.length > 0 ? condition.join(', ') : '未設定';

  return (
    <Flex align="center" justify="center" minHeight="100vh" mt={0} mb={30}>
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        pb={10}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/work.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            副業求人一覧
          </Text>
        </Box>
        <Stack spacing={4}>
          
          <Divider my={2} />
          {jobListings.length === 0 ? (
            <Text>該当する求人情報が見つかりませんでした。</Text>
          ) : (
            jobListings.map((job) => (
              <Box
                key={job.id}
                p={4}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
                w={{ base: '90%', lg: '50%' }}
                mx="auto"
              >
                {job.status === '確定' && (
                  <Text fontWeight="bold" color="black" fontSize="xl">
                    募集が終了しました
                  </Text>
                )}
                <Box
                  display="inline-block"
                  borderRadius="full"
                  px={3}
                  py={1}
                  bg="gray.100"
                >
                  <Text fontSize={15} color="#40BA8D">
                    ID: {job.id}
                  </Text>
                </Box>

                <HStack>
                  <Text fontWeight="bold" color="black">
                    一回 {job.salary}円
                  </Text>
                </HStack>

                <HStack>
                  <Text fontWeight="bold" color="black">
                    {job.title}
                  </Text>
                </HStack>

                {job.status !== '確定' && (
                  <Button
                    borderRadius="20"
                    backgroundColor="#949495"
                    marginTop="10px"
                    color="white"
                    _hover={{ backgroundColor: '#737373' }}
                    height="30px"
                    onClick={() => navigate(`/side-job-detail/${job.id}`)}
                  >
                    詳細を見る
                  </Button>
                )}
              </Box>
            ))
          )}
        </Stack>
        {/* <Button
          borderRadius="20"
          backgroundColor="#949495"
          marginTop="20px"
          color="white"
          _hover={{ backgroundColor: '#737373' }}
          height="30px"
          onClick={() => navigate(-1)}
          ml={8}
        >
          全ページに戻る
        </Button> */}
      </Box>
    </Flex>
  );
};
