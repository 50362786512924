import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  deleteDoc, // 追加
} from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import { PartJobListings } from '../../types/PartJobListings';
import {
  specialties,
  workTypes,
  facilityTypes,
  workDaysOptions,
} from '../../constants/constants';

type PartJobApplication = {
  doctorId: string;
  jobId: string;
  name: string;
  status: string;
  phoneNumber: string;
};

export const PartEdit: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const job = location.state as PartJobListings | undefined; // 受け取った求人情報
  const [applications, setApplications] = useState<PartJobApplication[]>([]);
  const [formData, setFormData] = useState<PartJobListings>({
    title: '',
    id: '',
    hospitalName: '',
    companyName: '',
    hospitalUID: '',
    prefectures: [],
    address: '',
    workDays: [],
    workTime: '',
    negotiableEntry: '',
    possibleOvertime: '',
    workTypes: [],
    salary: 0,
    transportation: '',
    facilityType: '',
    workContent: '',
    specialties: [],
    status: '',
  });

  useEffect(() => {
    if (!user) {
      console.error('User is not defined');
      return;
    }
    if (!job) {
      console.error('Job is not defined');
      return;
    }

    setFormData(job); // 編集用のフォームデータを設定

    const fetchApplications = async () => {
      try {
        const q = query(
          collection(db, 'partJobApplications'),
          where('jobId', '==', job.id)
        );
        const querySnapshot = await getDocs(q);
        const applicationsData = querySnapshot.docs.map((doc) => {
          const data = doc.data() as PartJobApplication;
          return {
            doctorId: data.doctorId,
            jobId: data.jobId,
            name: data.name,
            status: data.status,
            phoneNumber: data.phoneNumber,
          };
        });
        setApplications(applicationsData);
      } catch (error) {
        console.error('Error fetching applications: ', error);
      }
    };
    fetchApplications();
  }, [user, job]);

  const handleViewProfile = (doctorId: string) => {
    navigate(`/doctor-profile/${doctorId}`, { state: { jobId: job?.id } });
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleMultiSelectChange = (
    selected: any,
    fieldName: keyof PartJobListings
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selected.map((option: any) => option.value),
    }));
  };

  const handleEditConfirm = async () => {
    if (formData) {
      try {
        const jobRef = doc(db, 'PartJobListings', formData.id);
        await updateDoc(jobRef, formData);
        alert('求人情報が更新されました');
      } catch (error) {
        console.error('Error updating job: ', error);
        alert('求人情報の更新に失敗しました');
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm('本当にこの求人を削除しますか？')) {
      try {
        const jobRef = doc(db, 'PartJobListings', formData.id);
        await deleteDoc(jobRef);
        alert('求人が削除されました');
        navigate('/work-management-for-hospital'); // 求人管理画面に遷移
      } catch (error) {
        console.error('Error deleting job: ', error);
        alert('求人の削除に失敗しました');
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          申し込み管理
        </Heading>
        <Text>現在の申し込み状況</Text>
        {applications.length > 0 ? (
          applications.map((application) => (
            <Box
              key={application.doctorId}
              p={4}
              border="1px solid #ccc"
              borderRadius="md"
              mb={4}
            >
              <Text>医師名: {application.name}</Text>
              <Flex justify="flex-start" align="center" mt={2}>
                <Button
                  colorScheme="teal"
                  onClick={() => handleViewProfile(application.doctorId)}
                >
                  プロフィールを見る
                </Button>
                {application.status === '確定' && (
                  <Button ml={2} colorScheme="red" isDisabled>
                    確定
                  </Button>
                )}
              </Flex>
            </Box>
          ))
        ) : (
          <Text textAlign="center">申し込みがありません</Text>
        )}

        <Divider my={4} />
        {!user || !job ? (
          <Text textAlign="center">ユーザーまたは求人情報がありません</Text>
        ) : (
          <Stack spacing={4}>
            <Box p={4} border="1px solid #ccc" borderRadius="md">
              <Text fontSize="lg" fontWeight="bold">
                現在の求人情報
              </Text>
              <Text>求人名: {job.title}</Text>
              <Text>求人ID: {job.id}</Text>
              <Text>医療機関名: {job.hospitalName}</Text>
              <Text>曜日: {job.workDays.join(', ')}</Text>
              <Text>勤務時間: {job.workTime}</Text>
              <Text>勤務体系: {job.workTypes.join(', ')}</Text>
              <Text>給与: {job.salary}円</Text>
              <Text>交通費: {job.transportation}</Text>
              <Text>施設区分: {job.facilityType}</Text>
              <Text>勤務内容／条件: {job.workContent}</Text>
              <Text>求人状態: {job.status}</Text>
            </Box>
            <Box p={4} border="1px solid #ccc" borderRadius="md">
              <Text fontSize="lg" fontWeight="bold">
                求人情報編集
              </Text>
              <Text fontWeight="bold">曜日</Text>
              <MultiSelect
                options={workDaysOptions.map((workday) => ({
                  label: workday.label,
                  value: workday.value,
                }))}
                value={formData.workDays.map((workday) => ({
                  label: workday,
                  value: workday,
                }))}
                onChange={(selected: any) =>
                  handleMultiSelectChange(selected, 'workDays')
                }
                labelledBy="曜日"
              />
              <Text fontWeight="bold">医療機関名</Text>
              <Input
                name="hospitalName"
                placeholder="医療機関名"
                value={formData.hospitalName}
                onChange={handleInputChange}
              />
              <Text fontWeight="bold">勤務時間</Text>
              <Input
                name="workTime"
                placeholder="勤務時間"
                value={formData.workTime}
                onChange={handleInputChange}
              />
              <Text fontWeight="bold">勤務体系</Text>
              <MultiSelect
                options={workTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
                value={formData.workTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
                onChange={(selected: any) =>
                  handleMultiSelectChange(selected, 'workTypes')
                }
                labelledBy="勤務体系"
              />
              <Text fontWeight="bold">
                給料(半角数字 例:「90000円」→「90000」と入力)
              </Text>
              <Input
                name="salary"
                placeholder="給与"
                value={formData.salary}
                onChange={handleInputChange}
              />
              <Text fontWeight="bold">交通費</Text>
              <Input
                name="transportation"
                placeholder="交通費"
                value={formData.transportation}
                onChange={handleInputChange}
              />
              <Text fontWeight="bold">施設区分</Text>
              <Select
                name="facilityType"
                placeholder="施設区分"
                value={formData.facilityType}
                onChange={handleInputChange}
              >
                {facilityTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
              <Text fontWeight="bold">診療科</Text>
              <MultiSelect
                options={specialties.map((specialty) => ({
                  label: specialty,
                  value: specialty,
                }))}
                value={formData.specialties.map((specialty) => ({
                  label: specialty,
                  value: specialty,
                }))}
                onChange={(selected: any) =>
                  handleMultiSelectChange(selected, 'specialties')
                }
                labelledBy="診療科"
              />
              <Text fontWeight="bold">勤務内容／条件</Text>
              <Textarea
                name="workContent"
                placeholder="勤務内容／条件"
                value={formData.workContent}
                onChange={handleInputChange}
              />
              <Button mt={2} colorScheme="teal" onClick={handleEditConfirm}>
                更新を確定する
              </Button>
              <Button mt={2} colorScheme="red" onClick={handleDelete}>
                求人を削除する
              </Button>
              <Button mt={2} colorScheme="gray" onClick={handleBack}>
                戻る
              </Button>
            </Box>
          </Stack>
        )}
      </Box>
    </Flex>
  );
};

export default PartEdit;
