import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  CheckboxGroup,
  Wrap,
  WrapItem,
  Image,
  Icon,
  Link as ChakraLink,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { FC, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { prefectures } from '../../constants/constants';
import { FormData } from '../../types/FormData';
import {
  ArrowForwardIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  EmailIcon,
  ExternalLinkIcon,
  LockIcon,
} from '@chakra-ui/icons';

const days = ['月', '火', '水', '木', '金', '土', '日'];

type LoginData = {
  email: string;
  password: string;
};

export const Setting: FC = memo(() => {
  const {
    user,
    login,
    updateUserInfo,
    logout,
    loading,
    sendPasswordResetEmail,
  } = useAuth();
  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    formState: { errors: loginErrors },
  } = useForm<LoginData>();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const redirectTo =
    new URLSearchParams(location.search).get('redirect') || '/';

  const [loginError, setLoginError] = useState<string | null>(null);

  const specialtiesValue = watch('specialty', []) as string[];
  const possibleLocationsValue = watch('possibleLocations', []) as string[];
  const desiredWorkTypeValue = watch('desiredWorkType', []) as string[];
  const desiredDaysValue = watch('desiredDays', []) as string[];

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setValue('nickname', userData.nickname || '未入力');
          setValue('name', userData.name || '');
          setValue('workplace', userData.workplace || '');
          setValue('department', userData.department || '');
          setValue('years', userData.years || 0);
          setValue('email', userData.email || '');
        }

        const jobDocRef = doc(db, 'jobProfiles', user.uid);
        const jobDocSnap = await getDoc(jobDocRef);
        if (jobDocSnap.exists()) {
          const jobData = jobDocSnap.data();
          // setValue('specialty', jobData.specialty || []);
          setValue('years', jobData.years || 0);
          // setValue('workplace', jobData.workplace || '');
          setValue(
            'currentLocation',
            jobData.currentLocation || '選択してください'
          );
          setValue('possibleLocations', jobData.possibleLocations || []);
          setValue('desiredWorkType', jobData.desiredWorkType || []);
          setValue('desiredDays', jobData.desiredDays || []);
          setValue('skills', jobData.skills || '未入力');
          setValue(
            'desiredSalaryFullTime',
            jobData.desiredSalaryFullTime || '未入力'
          );
          setValue(
            'desiredSalaryPartTime',
            jobData.desiredSalaryPartTime || '未入力'
          );
          setValue('desiredSalarySpot', jobData.desiredSalarySpot || '未入力');
          setValue('workHistory', jobData.workHistory || '未入力');
          setValue('qualifications', jobData.qualifications || '未入力');
          setValue('importantFactors', jobData.importantFactors || '未入力');
        }
      };
      fetchData();
    }
  }, [user, setValue]);

  const onSubmit = async (data: FormData) => {
    if (user) {
      try {
        // usersコレクションのドキュメントを更新
        await updateDoc(doc(db, 'users', user.uid), {
          nickname: data.nickname,
          name: data.name,
          department: data.department,
          workplace: data.workplace,
          // specialties: data.specialty,
          years: data.years,
          email: data.email,
        });

        // jobProfilesコレクションのドキュメントを作成または更新
        await setDoc(doc(db, 'jobProfiles', user.uid), {
          years: data.years,
          currentLocation: data.currentLocation,
          possibleLocations: data.possibleLocations,
          desiredWorkType: data.desiredWorkType,
          desiredDays: data.desiredDays,
          skills: data.skills,
          desiredSalaryFullTime: data.desiredSalaryFullTime,
          desiredSalaryPartTime: data.desiredSalaryPartTime,
          desiredSalarySpot: data.desiredSalarySpot,
          workHistory: data.workHistory,
          qualifications: data.qualifications,
          importantFactors: data.importantFactors,
        });

        setEditMode(false);
        alert('情報が更新されました');
      } catch (error) {
        alert('情報の更新に失敗しました');
      }
    }
  };

  const onSubmitLogin = async (data: LoginData) => {
    try {
      await login(data.email, data.password);
      navigate(redirectTo);
    } catch (error) {
      setLoginError('パスワードが間違っています');
    }
  };

  const handlePasswordReset = async () => {
    if (user && user.email) {
      try {
        await sendPasswordResetEmail(user.email);
        alert('パスワード再設定メールを送信しました');
      } catch (error) {
        alert('パスワード再設定メールの送信に失敗しました');
      }
    }
  };

  if (!user) {
    return (
      <Flex align="center" justify="center" height="100vh" my={0}>
        <Box
          bg="white"
          w={{ base: '100%', md: 'sm' }}
          p={0}
          borderRadius="md"
          shadow="md"
        >
          <Box position="relative" textAlign="center">
            <Image
              src="/assets/images/register.png"
              alt="ログイン画像"
              mb={4}
            />
            <Text
              position="absolute"
              top="70%"
              left="50%"
              width="80%"
              transform="translate(-50%, -50%)"
              color="white"
              fontWeight="bold"
              fontSize="md"
            >
              会員の方はこちらからログインしてください。会員登録がまだの方は会員登録してください。
            </Text>
          </Box>
          <form onSubmit={handleSubmitLogin(onSubmitLogin)}>
            <Stack spacing={6} py={4} px={10}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="black" />
                </InputLeftElement>
                <Input
                  placeholder="メールアドレス"
                  _placeholder={{ color: 'black' }}
                  borderColor="black"
                  borderRadius="20"
                  pl="10"
                  {...registerLogin('email', {
                    required: 'メールアドレスが入力されていません',
                  })}
                />
              </InputGroup>
              {loginErrors.email && (
                <Text color="red.500">{loginErrors.email.message}</Text>
              )}
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="black" />
                </InputLeftElement>
                <Input
                  placeholder="パスワード"
                  _placeholder={{ color: 'black' }}
                  borderColor="black"
                  borderRadius="20"
                  pl="10"
                  type="password"
                  {...registerLogin('password', {
                    required: 'パスワードが入力されていません',
                  })}
                />
              </InputGroup>
              {loginErrors.password && (
                <Text color="red.500">{loginErrors.password.message}</Text>
              )}
              {loginError && <Text color="red.500">{loginError}</Text>}
              <Button
                type="submit"
                isLoading={loading}
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="20px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="40px"
                width="100%"
                mx="auto"
                display="block"
              >
                <Flex justify="space-between" align="center" width="100%">
                  <Box>ログイン</Box>
                  <Icon as={ArrowForwardIcon} />
                </Flex>
              </Button>
            </Stack>
          </form>
          <Text mt={-2} ml={10}>
            <Link
              to="/reset-password"
              style={{ color: '#126BC2', textDecoration: 'underline' }}
            >
              パスワードの再設定はこちら
            </Link>
          </Text>
          <Text mt={4} textAlign="center">
            <Link
              to={`/register?redirect=${encodeURIComponent(redirectTo)}`}
              style={{
                display: 'inline-block',
                textAlign: 'center',
                width: '80%',
                padding: '0.5rem',
                borderRadius: '25px',
                backgroundColor: '#949495',
                color: 'white',
                textDecoration: 'none',
                marginTop: '20px',
                marginBottom: '20px',
                fontWeight: 'bold',
                height: '40px',
                background: 'linear-gradient(to right, #03172D, #213C80)',
              }}
            >
              新規会員登録はこちら
            </Link>
          </Text>

          <Button
            as="a"
            href="https://m-e-racle.com/compaies/#company"
            target="_blank"
            rel="noopener noreferrer"
            borderRadius="20"
            marginTop="20px"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            w="80%"
            display="flex"
            justifyContent="center"
            mx="auto"
            style={{
              background: 'linear-gradient(to right, #03172D, #213C80)',
              borderRadius: 20,
            }}
            color="white"
          >
            <Flex justify="center" align="center" width="100%">
              <Box textAlign="center" width="90%">
                運営会社
              </Box>
              <Box>
                <Icon as={ExternalLinkIcon} />
              </Box>
            </Flex>
          </Button>

          <Button
            as="a"
            href="https://m-e-racle.com/contact/"
            target="_blank"
            rel="noopener noreferrer"
            borderRadius="20"
            marginTop="20px"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            w="80%"
            display="flex"
            justifyContent="center"
            mx="auto"
            style={{
              background: 'linear-gradient(to right, #03172D, #213C80)',
              borderRadius: 20,
            }}
            color="white"
          >
            <Flex justify="center" align="center" width="100%">
              <Box textAlign="center" width="90%">
                お問い合わせ
              </Box>
              <Box>
                <Icon as={ExternalLinkIcon} />
              </Box>
            </Flex>
          </Button>

          <Button
            as="a"
            href="https://m-e-racle.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            borderRadius="20"
            marginTop="20px"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            w="80%"
            display="flex"
            justifyContent="center"
            mx="auto"
            style={{
              background: 'linear-gradient(to right, #03172D, #213C80)',
              borderRadius: 20,
            }}
            color="white"
          >
            <Flex justify="center" align="center" width="100%">
              <Box textAlign="center" width="90%">
                プライバシーポリシー
              </Box>
              <Box>
                <Icon as={ExternalLinkIcon} />
              </Box>
            </Flex>
          </Button>

          
        </Box>
      </Flex>
    );
  }

  return (
    <Flex textAlign="center" justify="center" my={100}>
      <Box
        w={{ base: '100%', md: 'sm' }}
        borderRadius="md"
        shadow="md"
        bgColor="white"
        pb={10}
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/register.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="50%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            設定ページ
          </Text>
          <Text
            position="absolute"
            top="80%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="md"
          >
            詳細プロフィールを入力すると、医療機関からダイレクトスカウトメールが届きます。
          </Text>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} px={10}>
            {editMode ? (
              <>
                <Text fontWeight="bold" color="black">
                  ニックネーム
                </Text>
                <Input
                  placeholder="ニックネーム"
                  _placeholder={{ color: 'black' }}
                  {...register('nickname', {
                    required: 'ニックネームが入力されていません',
                  })}
                />
                {errors.nickname && (
                  <Text color="red.500">{errors.nickname.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  お名前
                </Text>
                <Input
                  placeholder="お名前"
                  _placeholder={{ color: 'white' }}
                  {...register('name', {
                    required: 'お名前が入力されていません',
                  })}
                />
                {errors.name && (
                  <Text color="red.500">{errors.name.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  勤務先
                </Text>
                <Input
                  placeholder="勤務先"
                  _placeholder={{ color: 'white' }}
                  {...register('workplace', {
                    required: '勤務先が入力されていません',
                  })}
                />
                {errors.workplace && (
                  <Text color="red.500">{errors.workplace.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  診療科
                </Text>
                <Input
                  placeholder="診療科"
                  _placeholder={{ color: 'white' }}
                  {...register('department', {
                    required: '診療科が入力されていません',
                  })}
                />
                {errors.department && (
                  <Text color="red.500">{errors.department.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  勤務年数
                </Text>
                <Select
                  placeholder="勤務年数"
                  {...register('years', {
                    required: '勤務年数が入力されていません',
                  })}
                >
                  {[...Array(50)].map((_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}年目
                    </option>
                  ))}
                </Select>
                {errors.years && (
                  <Text color="red.500">{errors.years.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  メールアドレス
                </Text>
                <Input
                  placeholder="メールアドレス"
                  {...register('email', {
                    required: 'メールアドレスが入力されていません',
                  })}
                />
                {errors.email && (
                  <Text color="red.500">{errors.email.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  在住都道府県
                </Text>
                <Select
                  placeholder="選択してください"
                  {...register('currentLocation', {
                    required: '在住都道府県が入力されていません',
                  })}
                >
                  {prefectures.map((prefecture) => (
                    <option key={prefecture} value={prefecture}>
                      {prefecture}
                    </option>
                  ))}
                </Select>
                {errors.currentLocation && (
                  <Text color="red.500">{errors.currentLocation.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  勤務可能都道府県
                </Text>
                <CheckboxGroup
                  value={possibleLocationsValue}
                  onChange={(values) =>
                    setValue('possibleLocations', values as string[])
                  }
                >
                  <Wrap spacing={2}>
                    {prefectures.map((prefecture) => (
                      <WrapItem key={prefecture}>
                        <Checkbox value={prefecture}>{prefecture}</Checkbox>
                      </WrapItem>
                    ))}
                  </Wrap>
                </CheckboxGroup>
                {errors.possibleLocations && (
                  <Text color="red.500">
                    {errors.possibleLocations.message}
                  </Text>
                )}
                <Text fontWeight="bold" color="black">
                  希望勤務形態
                </Text>
                <CheckboxGroup
                  value={desiredWorkTypeValue}
                  onChange={(values) =>
                    setValue('desiredWorkType', values as string[])
                  }
                >
                  <Wrap spacing={2}>
                    <WrapItem>
                      <Checkbox value="常勤" color="black">
                        常勤
                      </Checkbox>
                    </WrapItem>
                    <WrapItem>
                      <Checkbox value="非常勤" color="black">
                        非常勤
                      </Checkbox>
                    </WrapItem>
                    <WrapItem>
                      <Checkbox value="スポット" color="black">
                        スポット
                      </Checkbox>
                    </WrapItem>
                  </Wrap>
                </CheckboxGroup>
                {errors.desiredWorkType && (
                  <Text color="red.500">{errors.desiredWorkType.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  希望曜日
                </Text>
                <CheckboxGroup
                  value={desiredDaysValue}
                  onChange={(values) =>
                    setValue('desiredDays', values as string[])
                  }
                >
                  <Wrap spacing={2}>
                    {days.map((day) => (
                      <WrapItem key={day}>
                        <Checkbox value={day}>{day}</Checkbox>
                      </WrapItem>
                    ))}
                  </Wrap>
                </CheckboxGroup>
                {errors.desiredDays && (
                  <Text color="red.500">{errors.desiredDays.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  お持ちのスキル
                </Text>
                <Textarea
                  placeholder="例) 白内障の手術、ヒアルロン酸注入"
                  {...register('skills', {
                    required: 'お持ちのスキルが入力されていません',
                  })}
                />
                {errors.skills && (
                  <Text color="red.500">{errors.skills.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  希望報酬(常勤)
                </Text>
                <Input
                  placeholder="例)1200万(週４勤務)"
                  {...register('desiredSalaryFullTime', {
                    required: '希望報酬(常勤)が入力されていません',
                  })}
                />
                {errors.desiredSalaryFullTime && (
                  <Text color="red.500">
                    {errors.desiredSalaryFullTime.message}
                  </Text>
                )}
                <Text fontWeight="bold" color="black">
                  希望報酬(非常勤)
                </Text>
                <Input
                  placeholder="例)10万円(週1回)"
                  {...register('desiredSalaryPartTime', {
                    required: '希望報酬(非常勤)が入力されていません',
                  })}
                />
                {errors.desiredSalaryPartTime && (
                  <Text color="red.500">
                    {errors.desiredSalaryPartTime.message}
                  </Text>
                )}
                <Text fontWeight="bold" color="black">
                  希望報酬(スポット)
                </Text>
                <Input
                  placeholder="例)10万円/回"
                  {...register('desiredSalarySpot', {
                    required: '希望報酬(スポット)が入力されていません',
                  })}
                />
                {errors.desiredSalarySpot && (
                  <Text color="red.500">
                    {errors.desiredSalarySpot.message}
                  </Text>
                )}
                <Text fontWeight="bold" color="black">
                  これまでの勤務歴
                </Text>
                <Textarea
                  placeholder="例)2018年4月~2022年3月: 〇〇大学医学部附属病院眼科医局 2022年4月~現在:〇〇病院常勤医師"
                  {...register('workHistory', {
                    required: 'これまでの勤務歴が入力されていません',
                  })}
                />
                {errors.workHistory && (
                  <Text color="red.500">{errors.workHistory.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  お持ちの資格
                </Text>
                <Textarea
                  placeholder="例) 循環器内科専門医、産業医資格、"
                  {...register('qualifications', {
                    required: 'お持ちの資格が入力されていません',
                  })}
                />
                {errors.qualifications && (
                  <Text color="red.500">{errors.qualifications.message}</Text>
                )}
                <Text fontWeight="bold" color="black">
                  勤務する上で重視すること
                </Text>
                <Textarea
                  placeholder="例)子育てをしながらの勤務になるので、夜遅くなりすぎないこと。スキルが身につくこと。"
                  {...register('importantFactors', {
                    required: '勤務する上で重視することが入力されていません',
                  })}
                />
                {errors.importantFactors && (
                  <Text color="red.500">{errors.importantFactors.message}</Text>
                )}
                <Button
                  type="submit"
                  borderRadius="20"
                  marginTop="20px"
                  _hover={{ backgroundColor: '#737373' }}
                  height="40px"
                  w="80%"
                  mx="auto"
                  style={{
                    background: 'linear-gradient(to right, #03172D, #213C80)',
                    borderRadius: 20,
                  }}
                  color="white"
                >
                  <Flex justify="space-between" align="center" width="100%">
                    <Box>更新</Box>
                    <Icon as={CheckIcon} />
                  </Flex>
                </Button>
                <Button
                  borderRadius="20"
                  marginTop="20px"
                  _hover={{ backgroundColor: '#737373' }}
                  height="40px"
                  w="80%"
                  mx="auto"
                  style={{
                    background: 'linear-gradient(to right, #03172D, #213C80)',
                    borderRadius: 20,
                  }}
                  color="white"
                  onClick={() => setEditMode(false)}
                >
                  <Flex justify="space-between" align="center" width="100%">
                    <Box>キャンセル</Box>
                    <Icon as={CloseIcon} />
                  </Flex>
                </Button>
              </>
            ) : (
              <>
                <Text fontWeight="bold" color="black">
                  ニックネーム
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('nickname')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  お名前
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('name')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  勤務先
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('workplace')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  診療科
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('department')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  勤務年数
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('years')}年目</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  メールアドレス
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('email')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  在住都道府県
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('currentLocation')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  勤務可能都道府県
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">
                    {possibleLocationsValue.join(', ') || ''}
                  </Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  希望勤務形態
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">
                    {desiredWorkTypeValue.join(', ') || ''}
                  </Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  希望曜日
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{desiredDaysValue.join(', ') || ''}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  お持ちのスキル
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('skills')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  希望報酬(常勤)
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('desiredSalaryFullTime')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  希望報酬(非常勤)
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('desiredSalaryPartTime')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  希望報酬(スポット)
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('desiredSalarySpot')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  これまでの勤務歴
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('workHistory')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  お持ちの資格
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('qualifications')}</Text>
                </Box>

                <Text fontWeight="bold" color="black">
                  勤務する上で重視すること
                </Text>
                <Box border="1px" borderColor="gray.200" p={2} mt={-2} mb={4}>
                  <Text color="black">{watch('importantFactors')}</Text>
                </Box>

                <Button
                  borderRadius="20"
                  marginTop="20px"
                  _hover={{ backgroundColor: '#737373' }}
                  height="40px"
                  w="80%"
                  mx="auto"
                  style={{
                    background: 'linear-gradient(to right, #03172D, #213C80)',
                    borderRadius: 20,
                  }}
                  color="white"
                  onClick={() => setEditMode(true)}
                >
                  <Flex justify="space-between" align="center" width="100%">
                    <Box>編集する</Box>
                    <Icon as={EditIcon} />
                  </Flex>
                </Button>
              </>
            )}
            <Button
              borderRadius="20"
              marginTop="20px"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
              w="80%"
              mx="auto"
              style={{
                background: 'linear-gradient(to right, #03172D, #213C80)',
                borderRadius: 20,
              }}
              color="white"
              onClick={handlePasswordReset}
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box>パスワード再設定</Box>
                <Icon as={LockIcon} />
              </Flex>
            </Button>
            <Button
              borderRadius="20"
              marginTop="20px"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
              w="80%"
              mx="auto"
              style={{
                background: 'linear-gradient(to right, #03172D, #213C80)',
                borderRadius: 20,
              }}
              color="white"
              onClick={logout}
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box>ログアウトする</Box>
                <Icon as={ExternalLinkIcon} />
              </Flex>
            </Button>

            {/* 運営会社ボタン */}
            <Button
              as="a"
              href="https://m-e-racle.com/compaies/#company"
              target="_blank"
              rel="noopener noreferrer"
              borderRadius="20"
              marginTop="20px"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
              w="80%"
              mx="auto"
              style={{
                background: 'linear-gradient(to right, #03172D, #213C80)',
                borderRadius: 20,
              }}
              color="white"
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box>運営会社</Box>
                <Icon as={ExternalLinkIcon} />
              </Flex>
            </Button>

            {/* お問い合わせボタン */}
            <Button
              as="a"
              href="https://m-e-racle.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
              borderRadius="20"
              marginTop="20px"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
              w="80%"
              mx="auto"
              style={{
                background: 'linear-gradient(to right, #03172D, #213C80)',
                borderRadius: 20,
              }}
              color="white"
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box>お問い合わせ</Box>
                <Icon as={ExternalLinkIcon} />
              </Flex>
            </Button>

            {/* プライバシーポリシーボタン */}
            <Button
              as="a"
              href="https://m-e-racle.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              borderRadius="20"
              marginTop="20px"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
              w="80%"
              mx="auto"
              style={{
                background: 'linear-gradient(to right, #03172D, #213C80)',
                borderRadius: 20,
              }}
              color="white"
              
            >
              <Flex
                justify="space-between"
                align="center"
                width="100%"
              >
                <Box>プライバシーポリシー</Box>
                <Icon as={ExternalLinkIcon} />
              </Flex>
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
});

export default Setting;
