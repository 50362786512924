import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme/theme';
import { Router } from './router/Router';
import { Footer } from './components/organisms/layout/Footer';
import { AuthProvider } from './hooks/useAuth';
import { MobileFooter } from './components/organisms/layout/MobileFooter';
import { FixedFooter } from './components/organisms/layout/FixedFooter';
import { ConditionalFooter } from './components/organisms/layout/ConditionalFooter';

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <Router />
            <FixedFooter /> {/* フッターの条件分岐コンポーネント */}
            {/* <Footer /> */}
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;
