import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  Input,
  Image,
  HStack,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../firebase';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore';
import { JobListings } from '../../types/jobListings';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import { AtSignIcon, PlusSquareIcon, TimeIcon } from '@chakra-ui/icons';

type UserData = {
  name: string;
  email: string;
  phoneNumber: string;
  workplace: string;
  years: number;
  qualifications?: string;
  skills?: string;
  specialty?: string[];
  workHistory?: string;
  remarks?: string; // 追加
};

type HospitalUserData = {
  email: string;
};

export const SpotDetailConfirm: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const job = location.state as JobListings;
  const { user } = useAuth();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [remarks, setRemarks] = useState<string>(''); // 追加
  const [remarksError, setRemarksError] = useState<string>(''); // 追加
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data() as UserData;
          setUserData(data);
          setPhoneNumber(data.phoneNumber || '');
          setRemarks(data.remarks || ''); // 追加
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleConfirm = async () => {
    if (!phoneNumber) {
      setPhoneNumberError('電話番号を入力してください');
      return;
    }

    if (user && userData && job && job.id) {
      setIsLoading(true);

      const applicationData: any = {
        doctorId: user.uid,
        jobId: job.id,
        status: '未確',
        hospitalUID: job.hospitalUID,
        name: userData.name,
        email: userData.email,
        phoneNumber,
        workplace: userData.workplace,
        years: userData.years,
        remarks, // 追加
      };

      // Optional fields
      if (userData.qualifications)
        applicationData.qualifications = userData.qualifications;
      if (userData.skills) applicationData.skills = userData.skills;
      if (userData.specialty) applicationData.specialty = userData.specialty;
      if (userData.workHistory)
        applicationData.workHistory = userData.workHistory;

      try {
        // Job application dataを保存
        await addDoc(collection(db, 'jobApplications'), applicationData);

        // メール送信内容を生成
        const emailContent = `
        以下の内容で応募が完了しました:\n
        求人タイトル: ${job.title}\n
        医療機関名: ${job.hospitalName}\n
        都道府県: ${job.prefectures.join(', ')}\n
        掲載元企業: ${job.companyName}\n
        勤務地: ${job.address}\n
        勤務日: ${job.workDate}\n
        勤務時間: ${job.workTime}\n
        入りの相談: ${job.negotiableEntry}\n
        時間外勤務の可能性: ${job.possibleOvertime}\n
        勤務体系: ${job.workTypes.join(', ')}\n
        給与: ${job.salary}円\n
        交通費: ${job.transportation}\n
        施設区分: ${job.facilityType}\n
        勤務内容／条件: ${job.workContent}\n\n
        応募者情報:\n
        名前: ${userData.name}\n
        メールアドレス: ${userData.email}\n
        勤務先: ${userData.workplace}\n
        勤務年数: ${userData.years}年目\n
        電話番号: ${phoneNumber}\n
        備考: ${remarks}\n
      `;

        // 医療機関ユーザーのメールアドレスを取得
        const hospitalUserRef = doc(db, 'hospitalUsers', job.hospitalUID);
        const hospitalUserSnap = await getDoc(hospitalUserRef);
        const hospitalUserData = hospitalUserSnap.data() as HospitalUserData;

        // メール送信
        const sendEmail = async (
          email: string,
          subject: string,
          text: string
        ) => {
          await fetch(
            `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/sendWelcomeEmail`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, subject, text }),
            }
          );
        };

        await sendEmail(userData.email, 
          'スポット求人応募完了のお知らせ', 
          `以下の内容で申し込みが完了しました。掲載元企業の担当者から連絡が入りますので、お待ちください。\n 
          ${emailContent}`);
        await sendEmail(
          'medpark727@gmail.com',
          '新しいスポット求人の応募がありました',
          emailContent
        );
        await sendEmail(
          hospitalUserData.email,
          'スポット求人に新しい応募がありました',
          `あなたの求人「${job.title}」に新しい応募がありました。求職者に連絡を入れてください。 \n\n${emailContent}`
        );

        setIsLoading(false);
        navigate('/application-complete');
      } catch (error) {
        console.error('Error adding document: ', error);
        alert('申し込みの送信に失敗しました');
        setIsLoading(false);
      }
    }
  };


  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" mt={50} mb={40}>
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        pb={10}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/work.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            申し込み確認
          </Text>
        </Box>
        {job && userData ? (
          <Box ml={10}>
            <Box
              display="inline-block"
              borderRadius="full"
              px={3}
              py={1}
              bg="gray.100"
              mb={4}
            >
              <Text fontSize={15} color="#40BA8D">
                ID: {job?.id}
              </Text>
            </Box>

            <HStack>
              <Text fontWeight="bold" color="black">
                一回 {job?.salary}円
              </Text>
            </HStack>

            <HStack>
              <Text fontWeight="bold" color="black" mb={4}>
                {job?.title}
              </Text>
            </HStack>

            <HStack>
              <AtSignIcon />
              <Text color="black">{job?.address}</Text>
            </HStack>

            <HStack>
              <TimeIcon />
              <Text color="black">
                {Array.isArray(job?.workDate)
                  ? job?.workDate.join(', ')
                  : job?.workDate}
              </Text>
              <Text color="black">{job?.workTime}</Text>
            </HStack>

            <Divider my={6} w="80%" mx="auto" />

            <Box>
              <HStack mb={3}>
                <PlusSquareIcon />
                <Text fontSize="lg" color="black" fontWeight="bold">
                  待遇
                </Text>
              </HStack>
              <Text color="black" fontSize="sm" fontWeight="bold">
                交通費
              </Text>
              <Text color="black" mb={4} fontSize="sm">
                {job.transportation}
              </Text>
              <Text fontSize="sm" color="black" fontWeight="bold">
                入りの相談
              </Text>
              <Text fontSize="sm" color="black" mb={4}>
                {job.negotiableEntry}
              </Text>
              <Text color="black" fontSize="sm" fontWeight="bold">
                時間外勤務の可能性
              </Text>
              <Text fontSize="sm" color="black" mb={4}>
                {job.possibleOvertime}
              </Text>
              <Text color="black" fontSize="sm" fontWeight="bold">
                勤務内容／条件
              </Text>
              <Text fontSize="sm" color="black" mb={4}>
                {job.workContent}
              </Text>
              <Text color="black" fontSize="sm" fontWeight="bold">
                クリニック名
              </Text>
              <Text fontSize="sm" color="black" mb={4}>
                {job.hospitalName}
              </Text>
              <Text color="black" fontSize="sm" fontWeight="bold">
                掲載元企業
              </Text>
              <Text fontSize="sm" color="black" mb={4}>
                {job.companyName}
              </Text>
            </Box>

            <Divider my={6} w="80%" mx="auto" />

            <HStack mb={3}>
              <PlusSquareIcon />
              <Text fontSize="lg" color="black" fontWeight="bold">
                医師プロフィール
              </Text>
            </HStack>

            <Text fontSize="sm" fontWeight="bold" color="black">
              名前
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {userData.name}
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="black">
              メールアドレス
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {userData.email}
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="black">
              勤務先
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {userData.workplace}
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="black">
              勤務年数
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {userData.years}年目
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="black" mb={2}>
              電話番号
            </Text>
            <Input
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setPhoneNumberError('');
              }}
              placeholder="入力してください"
              isInvalid={!!phoneNumberError}
              mb={4}
              w="80%"
              _placeholder={{ color: 'black' }}
              color="black"
            />
            {phoneNumberError && (
              <Text color="red.500">{phoneNumberError}</Text>
            )}

            {/* 追加: 備考欄 */}
            <Text fontSize="sm" fontWeight="bold" color="black" mb={2}>
              応募にあたって伝えたいこと
            </Text>
            <Input
              value={remarks}
              onChange={(e) => {
                setRemarks(e.target.value);
                setRemarksError('');
              }}
              placeholder="備考を入力してください"
              isInvalid={!!remarksError}
              mb={4}
              w="80%"
              _placeholder={{ color: 'black' }}
              color="black"
            />
            {remarksError && <Text color="red.500">{remarksError}</Text>}

            <Flex
              color="black"
              mt={4}
              justify="center"
              gap={2}
              direction="column"
              w="80%"
            >
              <Button
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="10px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="30px"
                onClick={handleConfirm}
                isLoading={isLoading}
              >
                申し込みを確定する
              </Button>
              <Button
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="10px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="30px"
                onClick={handleBack}
                isDisabled={isLoading}
              >
                戻る
              </Button>
            </Flex>
          </Box>
        ) : (
          <Text textAlign="center">読み込み中...</Text>
        )}
      </Box>
    </Flex>
  );
};

export default SpotDetailConfirm;
