import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  SimpleGrid,
  useToast,
  HStack,
  Divider,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiSelect, Option } from 'react-multi-select-component';
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { JobListings } from '../../types/jobListings';
import { AtSignIcon, TimeIcon, EmailIcon, LockIcon } from '@chakra-ui/icons';
import {
  addMonths,
  format,
  isSameDay,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
} from 'date-fns';
import { ja } from 'date-fns/locale';
import { prefectures, specialties, workTypes } from '../../constants/constants';
import { useAuth } from '../../hooks/useAuth';
import '../../styles.css';

type FormData = {
  prefectures: string[];
  workDates: string[];
  workTypes: string[];
  specialties: string[];
  salaryRange: Option[];
};

const salaryOptions: Option[] = [
  { label: '80,000円 - 90,000円', value: '80000-90000' },
  { label: '90,000円 - 100,000円', value: '90000-100000' },
  { label: '100,000円 - 110,000円', value: '100000-110000' },
  { label: '110,000円 - 120,000円', value: '110000-120000' },
  { label: '120,000円以上', value: '120000-' },
];

const CalendarComponent: FC<{
  selectedDates: Date[];
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
}> = ({ selectedDates, setSelectedDates }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const startOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0
  );

  const startDate = startOfWeek(startOfMonth, { weekStartsOn: 0 });
  const endDate = endOfWeek(endOfMonth, { weekStartsOn: 0 });

  const datesArray = eachDayOfInterval({ start: startDate, end: endDate });

  const handleDateClick = (date: Date) => {
    const alreadySelected = selectedDates.some((selectedDate) =>
      isSameDay(selectedDate, date)
    );
    if (alreadySelected) {
      setSelectedDates(selectedDates.filter((d) => !isSameDay(d, date)));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={4}>
        <Button
          size="sm"
          onClick={() => setCurrentMonth(addMonths(currentMonth, -1))}
        >
          前月
        </Button>
        <Text fontWeight="bold" fontSize="lg">
          {format(currentMonth, 'yyyy年 MMMM', { locale: ja })}
        </Text>
        <Button
          size="sm"
          onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
        >
          次月
        </Button>
      </Flex>
      <SimpleGrid columns={7} spacing={2}>
        {['日', '月', '火', '水', '木', '金', '土'].map((day) => (
          <Text key={day} textAlign="center" fontWeight="bold">
            {day}
          </Text>
        ))}
        {datesArray.map((date) => {
          const isSelected = selectedDates.some((selectedDate) =>
            isSameDay(selectedDate, date)
          );
          const isCurrentMonth = date.getMonth() === currentMonth.getMonth();
          const isToday = isSameDay(date, new Date());

          return (
            <Button
              key={date.toString()}
              onClick={() => handleDateClick(date)}
              size="sm"
              variant="ghost"
              bg={isSelected ? 'teal.500' : isToday ? 'gray.200' : 'white'}
              color={isSelected ? 'white' : isToday ? 'black' : 'gray.800'}
              opacity={isCurrentMonth ? 1 : 0.4}
              _hover={{ bg: 'teal.100' }}
            >
              {format(date, 'd')}
            </Button>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export const SpotSearch: FC = () => {
  const { handleSubmit } = useForm<FormData>();
  const { user, login, loading } = useAuth();
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectedPrefectures, setSelectedPrefectures] = useState<Option[]>([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState<Option[]>([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState<Option[]>([]);
  const [selectedSalaryRange, setSelectedSalaryRange] = useState<Option[]>([]);
  const [allJobListings, setAllJobListings] = useState<JobListings[]>([]);
  const navigate = useNavigate();
  const toast = useToast();
  const [loginError, setLoginError] = useState<string | null>(null);

  const onSubmit = () => {
    if (selectedDates.length === 0) {
      toast({
        title: 'エラー',
        description: '少なくとも一つの日付を選択してください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formattedDates = selectedDates.map((date) =>
      format(date, 'yyyy-MM-dd')
    );
    const prefectureValues = selectedPrefectures.map((p) => p.value);
    const workTypeValues = selectedWorkTypes.map((w) => w.value);
    const specialtyValues = selectedSpecialties.map((s) => s.value);
    const salaryRanges = selectedSalaryRange.map((s) => s.value);

    navigate('/spot-results', {
      state: {
        workDates: formattedDates,
        prefectures: prefectureValues,
        workTypes: workTypeValues,
        specialties: specialtyValues,
        salaryRange: salaryRanges,
      },
    });
  };

  const handleLogin = async (email: string, password: string) => {
    try {
      await login(email, password);
    } catch (error) {
      setLoginError('ログインに失敗しました。もう一度お試しください。');
    }
  };

  useEffect(() => {
    const fetchAllJobListings = async () => {
      try {
        const q = query(collection(db, 'jobListings'), orderBy('workDate'));
        const querySnapshot = await getDocs(q);
        const jobListingsData = querySnapshot.docs.map((doc) => {
          const data = doc.data() as Omit<JobListings, 'id'>;
          return { id: doc.id, ...data };
        });
        setAllJobListings(jobListingsData);
      } catch (error) {
        console.error('求人情報の取得に失敗しました:', error);
      }
    };

    fetchAllJobListings();
  }, []);

  if (!user) {
    return (
      <Flex align="center" justify="center" height="100vh" my={0}>
        <Box
          bg="white"
          w={{ base: '100%', md: 'sm' }}
          p={0}
          borderRadius="md"
          shadow="md"
        >
          <Box position="relative" textAlign="center">
            <Image
              src="/assets/images/register.png"
              alt="ログイン画像"
              mb={4}
            />
            <Text
              position="absolute"
              top="70%"
              left="50%"
              width="80%"
              transform="translate(-50%, -50%)"
              color="white"
              fontWeight="bold"
              fontSize="md"
            >
              会員の方はこちらからログインしてください。会員登録がまだの方は会員登録してください。
            </Text>
          </Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              const email = formData.get('email') as string;
              const password = formData.get('password') as string;
              handleLogin(email, password);
            }}
          >
            <Stack spacing={6} py={4} px={10}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="black" />
                </InputLeftElement>
                <Input
                  placeholder="メールアドレス"
                  _placeholder={{ color: 'black' }}
                  borderColor="black"
                  borderRadius="20"
                  pl="10"
                  name="email"
                  required
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="black" />
                </InputLeftElement>
                <Input
                  placeholder="パスワード"
                  _placeholder={{ color: 'black' }}
                  borderColor="black"
                  borderRadius="20"
                  pl="10"
                  type="password"
                  name="password"
                  required
                />
              </InputGroup>
              {loginError && <Text color="red.500">{loginError}</Text>}
              <Button
                type="submit"
                isLoading={loading}
                borderRadius="20"
                backgroundColor="#949495"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="40px"
                width="100%"
                mx="auto"
                display="block"
              >
                ログイン
              </Button>
            </Stack>
          </form>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" minHeight="100vh" mt={50}>
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        pb={10}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/work.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            スポット求人検索
          </Text>
        </Box>
        <Box w="90%" mx="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <Text fontWeight="bold" color="black">
                都道府県
              </Text>
              <MultiSelect
                options={prefectures.map((pref) => ({
                  label: pref,
                  value: pref,
                }))}
                value={selectedPrefectures}
                onChange={setSelectedPrefectures}
                labelledBy="都道府県を選択"
              />
              <Box>
                <Text mb={2} fontWeight="bold" color="black">
                  日付を選択(複数選択可能)
                </Text>
                <CalendarComponent
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                />
              </Box>
              <Text fontWeight="bold" color="black">
                勤務体系
              </Text>
              <MultiSelect
                options={workTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
                value={selectedWorkTypes}
                onChange={setSelectedWorkTypes}
                labelledBy="勤務体系を選択"
              />
              <Text fontWeight="bold" color="black">
                診療科
              </Text>
              <MultiSelect
                options={specialties.map((specialty) => ({
                  label: specialty,
                  value: specialty,
                }))}
                value={selectedSpecialties}
                onChange={setSelectedSpecialties}
                labelledBy="診療科を選択"
              />
              <Text fontWeight="bold" color="black">
                給料範囲
              </Text>
              <MultiSelect
                options={salaryOptions}
                value={selectedSalaryRange}
                onChange={setSelectedSalaryRange}
                labelledBy="給料範囲を選択"
              />
              <Button
                type="submit"
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="20px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="40px"
              >
                検索する
              </Button>
            </Stack>
          </form>
        </Box>

        <Divider my={4} />

        {/* すべての求人情報のリスト */}
        <Box p={4}>
          <Heading size="md" mb={4}>
            全てのスポット求人一覧
          </Heading>
          {allJobListings.map((job) => (
            <Box
              key={job.id}
              p={4}
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
              w={{ base: '90%', lg: '50%' }}
              mx="auto"
              mb={4}
            >
              {job.status === '確定' && (
                <Text fontWeight="bold" color="black" fontSize="xl">
                  募集が終了しました
                </Text>
              )}
              <Box
                display="inline-block"
                borderRadius="full"
                px={3}
                py={1}
                bg="gray.100"
              >
                <Text fontSize={15} color="#40BA8D">
                  ID: {job.id}
                </Text>
              </Box>

              <HStack>
                <Text fontWeight="bold" color="black">
                  一回 {job.salary}円
                </Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold" color="black">
                  {job.title}
                </Text>
              </HStack>
              <HStack>
                <AtSignIcon />
                <Text color="black">{job.address}</Text>
              </HStack>
              <HStack>
                <TimeIcon />
                <Text color="black">
                  {Array.isArray(job.workDate)
                    ? job.workDate.join(', ')
                    : job.workDate}
                </Text>
                <Text color="black">{job.workTime}</Text>
              </HStack>

              {job.status !== '確定' && (
                <Button
                  borderRadius="20"
                  backgroundColor="#949495"
                  marginTop="10px"
                  color="white"
                  _hover={{ backgroundColor: '#737373' }}
                  height="30px"
                  onClick={() => navigate(`/spot-detail/${job.id}`)}
                >
                  詳細を見る
                </Button>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Flex>
  );
};

export default SpotSearch;
