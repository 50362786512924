import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FC, memo, useState } from 'react';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onClickHome: () => void;
  onClickLogin: () => void;
  onClickSetting: () => void;
  onClickRegister: () => void;
  onClickScout: () => void;
  onClickSpotSearch: () => void;
  onClickPartSearch: () => void;
  onClickSideJobSearch: () => void;
  onClickWorkManagementForDoctor: () => void;
};

export const Menudrawer: FC<Props> = memo((props) => {
  const {
    onClose,
    isOpen,
    onClickHome,
    onClickLogin,
    onClickSetting,
    onClickScout,
    onClickSpotSearch,
    onClickPartSearch,
    onClickSideJobSearch,
    onClickWorkManagementForDoctor,
  } = props;

  const [pendingCallback, setPendingCallback] = useState<(() => void) | null>(
    null
  );

  const handleClick = (callback: () => void) => {
    return () => {
      onClose();
      setPendingCallback(() => callback);
    };
  };

  const handleCloseComplete = () => {
    if (pendingCallback) {
      pendingCallback();
      setPendingCallback(null);
    }
  };

  return (
    <Drawer
      placement="left"
      size="md"
      onClose={onClose}
      isOpen={isOpen}
      onCloseComplete={handleCloseComplete}
    >
      <DrawerOverlay>
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody p={0} bg="gray.100">
            <Button w="100%" onClick={handleClick(onClickHome)}>
              TOP
            </Button>
            <Button w="100%" onClick={handleClick(onClickSpotSearch)}>
              スポット
            </Button>
            <Button w="100%" onClick={handleClick(onClickPartSearch)}>
              非常勤
            </Button>
            <Button w="100%" onClick={handleClick(onClickSideJobSearch)}>
              副業
            </Button>
            {/* <Button w="100%" onClick={handleClick(onClickWorkManagementForDoctor)}>
              求人管理
            </Button> */}
            {/* <Button w="100%" onClick={handleClick(onClickScout)}>
              メッセージ
            </Button> */}
            <Button w="100%" onClick={handleClick(onClickSetting)}>
              マイページ
            </Button>
            <Button w="100%" onClick={handleClick(onClickLogin)}>
              ログイン
            </Button>
            <Button
              w="100%"
              as="a"
              href="https://m-e-racle.com/company/" // 外部リンクのURLを設定
              target="_blank"
              rel="noopener noreferrer"
              mt={2}
            >
              運営会社
            </Button>
            <Button
              w="100%"
              as="a"
              href="https://m-e-racle.com/contact/" // 外部リンクのURLを設定
              target="_blank"
              rel="noopener noreferrer"
              mt={2}
            >
              問い合わせ
            </Button>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
});

