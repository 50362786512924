import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Input,
  Textarea,
  Text,
  Select,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  specialties,
} from '../../constants/constants';
import { MultiSelect } from 'react-multi-select-component';


type SideJobFormData = {
  title: string;
  companyName: string;
  specialties: string[];
  salary: string; // 一時的にstringに変更
  workContent: string;
};

export const SideJobRegister: FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SideJobFormData>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [companyName, setCompanyName] = useState<string>('');
  const [hospitalName, setHospitalName] = useState<string>('');
  
  const [selectedSpecialties, setSelectedSpecialties] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (user) {
      const fetchHospitalData = async () => {
        const docRef = doc(db, 'hospitalUsers', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCompanyName(data.companyName);
          setValue('companyName', data.companyName);
        }
      };
      fetchHospitalData();
    }

    if (location.state) {
      const SideJobFormData = location.state as SideJobFormData;
      Object.keys(SideJobFormData).forEach((key) => {
        setValue(key as keyof SideJobFormData, SideJobFormData[key as keyof SideJobFormData]);
      });
    }
  }, [user, setValue, location.state]);

  const onSubmit = (data: SideJobFormData) => {
    const specialtyValues = selectedSpecialties.map((s) => s.value);
    const SideJobFormData = {
      ...data,
      specialties: specialtyValues,
      salary: parseInt(data.salary), // ここで数値に変換
    };
    navigate('/side-job-register-confirm', { state: SideJobFormData });
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          医師の副業求人登録
        </Heading>
        <Divider my={4} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Text>求人タイトル</Text>
            <Input
              placeholder="例) 日給12万円！ 週末の当直業務 インセンティブあり！駅近！"
              {...register('title', {
                required: '求人タイトルが入力されていません',
              })}
            />
            <Text>会社名</Text>
            <Input
              placeholder="会社名"
              {...register('companyName', {
                required: '会社名が入力されていません',
              })}
              value={companyName}
              readOnly
            />
            {errors.companyName && (
              <Text color="red.500">{errors.companyName.message}</Text>
            )}
            
            <Text>診療科</Text>
            <MultiSelect
              options={specialties.map((specialty) => ({
                label: specialty,
                value: specialty,
              }))}
              value={selectedSpecialties}
              onChange={setSelectedSpecialties}
              labelledBy="診療科を選択"
            />
            {errors.specialties && (
              <Text color="red.500">{errors.specialties.message}</Text>
            )}
            <Text>給与(半角数字のみ)</Text>
            <Input
              placeholder="例:「90000円」→「90000」と入力"
              {...register('salary', { required: '給与が入力されていません' })}
            />
            {errors.salary && (
              <Text color="red.500">{errors.salary.message}</Text>
            )}
           
            <Text>業務内容・締切・条件等</Text>
            <Textarea
              placeholder="例) 休日の内科外来業務です。患者数は20~30人/日です。(できるだけ詳細にご記入ください)"
              {...register('workContent', {
                required: '勤務内容／条件が入力されていません',
              })}
            />
            {errors.workContent && (
              <Text color="red.500">{errors.workContent.message}</Text>
            )}

            <Button type="submit" colorScheme="teal">
              登録内容を確認
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};
