import { Box, Button, Flex, Text, Avatar } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, CalendarIcon, RepeatIcon } from '@chakra-ui/icons';
import { FC } from 'react';

export const FixedFooter: FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      bg="gray.100"
      p={4}
      height="50px" // 高さを50pxに設定
      boxShadow="0px -2px 5px rgba(0, 0, 0, 0.1)"
      zIndex="10"
    >
      <Flex justify="space-around" align="center" height="100%">
        {' '}
        {/* フッター全体の中央寄せ */}
        <Button
          variant="ghost"
          onClick={() => navigate('/part-search')}
          flexDirection="column"
          alignItems="center" // アイコンと文字の中央寄せ
        >
          <SearchIcon boxSize={4} />
          <Text fontSize="sm">非常勤</Text>
        </Button>
        <Button
          variant="ghost"
          onClick={() => navigate('/spot-search')}
          flexDirection="column"
          alignItems="center"
        >
          <CalendarIcon boxSize={4} />
          <Text fontSize="sm">スポット</Text>
        </Button>
        <Button
          variant="ghost"
          onClick={() => navigate('/side-job-results')}
          flexDirection="column"
          alignItems="center"
        >
          <RepeatIcon boxSize={4} />
          <Text fontSize="sm">副業</Text>
        </Button>
        <Button
          variant="ghost"
          onClick={() => navigate('/setting')}
          flexDirection="column"
          alignItems="center"
        >
          <Avatar boxSize={4} />
          <Text fontSize="sm">マイページ</Text>
        </Button>
      </Flex>
    </Box>
  );
};
